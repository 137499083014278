import React, { Component } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { getProfile } from './UserFunctions'
import {Link} from "react-router-dom";
class Profile extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            id: ''
        }
    }

    componentDidMount() {
        getProfile().then(res => {
            this.setState({
                name: res.data.name,
                email: res.data.email,
                phone: res.data.phone,
                address:res.data.address,
                location:res.data.location,
                id:res.data.id
            })
        })
    }
  render() {
    return (
      <div>
        <Header />
        <main role="main">
        <div class="container">
        <h3>Profile</h3>
          <div class="row">
          <div class="col-sm-3">
          <Link to={`/my-post/${this.state.id}`} >My Ads</Link>
          </div>
          <div class="col-sm-9">
          <form action="#">
          <div align="center"><h5>User Detail</h5></div>
          <div className="form-group">   
          {this.state.name}
          </div>
          <div class="form-group">
          {this.state.email}
          </div>
          <div class="form-group">
          {this.state.phone}
          </div>
          <div class="form-group">
          {this.state.address}
          </div>
          <div class="form-group">
          {this.state.location}
          </div>
          
          </form>
          </div>
          </div>
          </div>
        
          
        </main>         
        <Footer />
      </div>
       
    );
  }
}
export default Profile;