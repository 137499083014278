import React, { Component } from "react";

class RegisterContent extends Component {
  render() {
    
    return (
      <section className="section background-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
           <h2>Banner</h2> 

            </div>
          </div>
        </div>
            <div className="container">
              <br/>
               <h4>Gallery</h4>
              
             
            </div>
      </section>
    );
  }
}

export default RegisterContent;