import axios from 'axios'
import Config from "./../../config";
export const register = newUser => {
    return axios
        .post('api/register', newUser, {
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            console.log(response)
        })
        .catch(err => {
            console.log(err)
        })
}

export const login = user => {
    return axios
        .post(
            `${Config.apiurl}api/login`,
            {
                email: user.email,
                password: user.password
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(response => {
            localStorage.setItem('usertoken', response.data.access_token)
            return response.data.access_token
        })
        .catch(err => {
            console.log(err)
        })
}

export const getProfile = () => {
    return axios
        .get(`${Config.apiurl}api/me`, {
            headers: { Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            console.log(response)
            return response.data
        })
        .catch(err => {
            console.log(err)
        })
}


export const getAd = (userId) => {
    return axios
        .get(`${Config.apiurl}api/getAdd/${userId}`, {
            headers: { Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            console.log(response)
            return response.data
        })
        .catch(err => {
            console.log(err)
        })
}

export const isAuthenticated = () => {
   // console.log(localStorage.getItem('usertoken'));
    if (localStorage.getItem('usertoken') === "undefined") {
        return false;
    }
    if (!localStorage.getItem('usertoken')) {
        return false;
    }

    return true;
}
