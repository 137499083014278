import React, { Component } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
//import Slider from "../common/Slider";
import MainPageContent from "../common/MainPageContent";


class Home extends Component {
  

  render() {   
    
    return (
      <div>
        <Header />
        <main role="main">    
           <MainPageContent />
        </main>         
        <Footer />
      </div>
    );
  }
}
export default Home;
