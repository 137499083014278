import axios from 'axios'
import Config from "./../../config";

export const LinkDetail = (tutId) => {
    return axios
        .get(`${Config.apiurl}api/subcategory/${tutId}`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            //alert(response.data.topic_heading);
            //tutorial=json_encode(response.data);
            //console.log(tutorial);
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}

export const TutorialDetail = (tutId,Id) => {
    return axios
        .get(`${Config.apiurl}api/tutorial/${tutId}/${Id}`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            //alert(response.data.topic_heading);
            //tutorial=json_encode(response.data);
            //console.log(tutorial);
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}

export const ExampleLinkDetail = (tutId) => {
    return axios
        .get(`${Config.apiurl}api/subcategoryexample/${tutId}`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            //alert(response.data.topic_heading);
            //tutorial=json_encode(response.data);
            //console.log(tutorial);
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}

export const ExampleDetail = (tutId,Id) => {
    return axios
        .get(`${Config.apiurl}api/example/${tutId}/${Id}`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            //alert(response.data.topic_heading);
            //tutorial=json_encode(response.data);
            //console.log(tutorial);
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}

export const ExampleImage = (tutId,Id) => {
    return axios
        .get(`${Config.apiurl}api/exampleimage/${tutId}/${Id}`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            //alert(response.data.topic_heading);
            //tutorial=json_encode(response.data);
            //console.log(response.data);
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}

export const ExampleLink = (tutId,Id) => {
    return axios
        .get(`${Config.apiurl}api/examplelink/${tutId}/${Id}`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            //alert(response.data.topic_heading);
            //tutorial=json_encode(response.data);
            //console.log(tutorial);
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}


export const InterviewDetail = (tutId) => {
    return axios
        .get(`${Config.apiurl}api/interview/${tutId}`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            //alert(response.data.topic_heading);
            //tutorial=json_encode(response.data);
            //console.log(tutorial);
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}


export const SuccessStory = () => {
    return axios
        .get(`${Config.apiurl}api/successstory`, {
            headers: { 
                "content-type": "application/json",
             }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.log(err)
        })
}