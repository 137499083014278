import React, { Component } from "react";
import {Link} from "react-router-dom";
import Config from "./../../config";
import loadingImage from "../../assets/img/loading.gif";
import { SuccessStory } from './../pages/PublicFunctions';


class MainPageContent extends Component {

  constructor() {
        super()
        this.state = {
            posts:[],
            isLoading : true
        }
    }

     componentDidMount() {
        

        (window.adsbygoogle = window.adsbygoogle || []).push({});

        SuccessStory().then(res => {
            this.setState({
                posts: res.data,
                isLoading: false
                
            })
        })
    }

 

  render() {
    const {posts,isLoading}= this.state;
    return (
      
      <div className="background">
      {isLoading && (
              <div className="loading">
                <img src={loadingImage} alt={loadingImage} className="mx-auto d-block loading-image" ></img>
              </div>
            )}
<div className="container">    
  <div className="row">
    <div className="col-sm-3">
    <div class="box5"><b>Success Story</b></div>
{posts.map((post, i) => {
            return (
            <div key={i}> 
            <div className="content">
            <div className="col-sm-4"> <img className="img-circle" height="65" width="65" src={`${Config.apiurl}public/upload_images/`+post.image_path} /></div> 
            <div className="col-sm-8"><b>{post.candidate_name}</b></div>
            <div className="col-sm-12">
            <div dangerouslySetInnerHTML={{__html: post.topic_desc}}></div>
            </div>
            </div>
            </div>
            
            )
  })}
    <div class="box5"><b>Popular Video</b></div>
    <iframe width="250" height="200" src="https://www.youtube.com/embed/f7V_hjkAe8U" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
 <iframe width="250" height="200" src="https://www.youtube.com/embed/86mcsAUi3M8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    

    </div>
    <div className="col-sm-7">
     <div className="row">
      <div className="col-sm-12">
      <div className="head1">WELCOME TO IT TRAINING ONLINE</div>
      <div className="head2">
      IT training online is the best Online Tutorial for Freshers, which was established in the year 2010. It is offering online tutorials in a wide range of IT topics with Live practical example in very easy manner. Our site www.ittrainingonline.co.in provides online tutorials in six important topics- PHP, JavaScript, jQuery, Ajax, Codeigniter and Laravel. All beginners, BCA, MCA, Btech, students, can pursue these courses from our site to enhance their knowledge about these programming languages. We also have our physical existence in Janakpuri...
      <a href="https://www.ittrainingonline.co.in/user/aboutus">More</a>
      </div>
      </div>
        
      </div>
      
      <div class="box2 head3">Tutorial, Example & Interview Questions</div>
        <div className="row">
        <div className="col-sm-4">
         <div align="center" className="div1">PHP Tutorial</div>
          
          <div className="box1">
           <div className="left">
          <div className="width1">Control Statement</div>
          <div className="width1">Function & Array</div>
          <div className="width1">OOPS & Database</div>
          <div className="width1">GD Library</div>
          <div className="width1">Regular expressions</div>
           </div>
           <div className="clearfix"></div>
          <div align="center">
       <Link to="/tutorial/php/1" className="btn btn-danger">Learn</Link>
       </div>
          <div className="clearfix"></div>
            </div>
         
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">PHP Example</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">Food Menu</div>
          <div className="width1">Validation</div>
          <div className="width1">Login Script</div>
         <div className="width1">Forgot password</div>
          <div className="width1">Searching</div>
           </div>
          <div className="clearfix"></div>
          <div align="center">
          <Link to="/example/php/1" className="btn btn-danger">Learn</Link>
       </div>
          <div className="clearfix"></div>
          </div>
        </div>
        <div className="col-sm-4">
         <div align="center" className="div1">PHP Interview</div>
          <div className="box1">
          
           
           <div className="left">
          
          <div className="width1">Pagination</div>
          <div className="width1">Captcha code</div>
          <div className="width1">Remember Me</div>
          <div className="width1">Mysql to MSExcel</div>
          
          <div className="width1">Thumbnail</div>
          </div>
         <div className="clearfix"></div>
          <div align="center">
       <Link to="/interview/php" className="btn btn-danger">Learn</Link>
       </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">Javascript Tutorial</div>
          <div className="box1">
          
          
           <div className="left">
          
          <div className="width1">Regular Expressions</div>
          <div className="width1">Javascript Objects</div>
          <div className="width1">DOM Object</div>
          <div className="width1">Built in Object</div>
          <div className="width1">Custom Object</div>
          </div>
          <div className="clearfix"></div>
           <div align="center">
       <Link to="/tutorial/javascript/3" className="btn btn-danger">Learn</Link>  </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">Javascript Example</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">Button Effect</div>
          <div className="width1">CheckBox Validation</div>
          <div className="width1">Alert Validation</div>
          <div className="width1">Border Validation</div>
          <div className="width1">Numeric Validation</div>
           </div>
          <div className="clearfix"></div>
           <div align="center">
       <Link to="/example/javascript/23" className="btn btn-danger">Learn</Link>
       </div>
          <div className="clearfix"></div>
          </div>
        </div>
        <div className="col-sm-4">
         <div align="center" className="div1">Javascript Interview</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">Javascript Error</div>
          <div className="width1">Pop up boxes</div>
          <div className="width1">Java and Javascript</div>
          <div className="width1">Confirmation box</div>
          <div className="width1">undeclared variable</div>
           </div>
          <div className="clearfix"></div>
           <div align="center">
       <Link to="/interview/javascript" className="btn btn-danger">Learn</Link>
       </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">jQuery Tutorial</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">jQuery Introduction</div>
          <div className="width1">jQuery Selectors</div>
          <div className="width1">jQuery Events</div>
          <div className="width1">jQuery Effect</div>
           <div className="width1">jQuery Animation</div>
          
           </div>
           <div className="clearfix"></div>
          <div align="center">
         <Link to="/tutorial/jquery/64" className="btn btn-danger">Learn</Link>    </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">jQuery Example</div>
          <div className="box1">
          
          
           <div className="left">
          
         <div className="width1">jQuery Animation</div>
           <div className="width1">Container box</div>
            <div className="width1">jQuery Slideshow</div>
           <div className="width1">Button Disable</div>
            <div className="width1">jQuery Form Submit</div>
          
          </div>
          <div className="clearfix"></div>
           <div align="center">
          <Link to="/example/jquery/63" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
        <div className="col-sm-4">
         <div align="center" className="div1">jQuery Interview</div>
          <div className="box1">
          
          
           <div className="left">
          
         <div className="width1">jQuery selectors</div>
           <div className="width1">Javascript library</div>
            <div className="width1">jQuery version</div>
           <div className="width1">jQuery effects</div>
            <div className="width1">Under Construction</div>
          
          </div>
          <div className="clearfix"></div>
           <div align="center">
          <Link to="/interview/jquery" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">Ajax Tutorial</div>
          <div className="box1">
          
           
           <div className="left">
          
          <div className="width1">Library Funtion</div>
          <div className="width1">GD Library</div>
          <div className="width1">Session</div>
          <div className="width1">Cookies</div>
          <div className="width1">Email</div>
          </div>
          <div className="clearfix"></div>
           <div align="center">
         <Link to="/tutorial/ajax/4" className="btn btn-danger">Learn</Link>  </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
        
         <div className="col-sm-4">
         <div align="center" className="div1">Ajax Example</div>
          <div className="box1">
          
           
           <div className="left">
          <div className="width1">Pagination</div>
          <div className="width1">GD Library</div>
          <div className="width1">Session</div>
          <div className="width1">Cookies</div>
          <div className="width1">Email</div>
          </div>
          <div className="clearfix"></div>
           <div align="center">
          <Link to="/example/ajax/33" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
        <div className="col-sm-4">
         <div align="center" className="div1">Ajax Interview</div>
          <div className="box1">
          
           
           <div className="left">
          <div className="width1">Different type of state</div>
          <div className="width1">Asynchronous Ajax</div>
          <div className="width1">Advantage of Ajax</div>
          <div className="width1">Disadvantage of Ajax</div>
          <div className="width1">More on Ajax</div>
          </div>
          <div className="clearfix"></div>
           <div align="center">
          <Link to="/interview/ajax" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">Codeignitor Tutorial</div>
          <div className="box1">
          
          
           <div className="left">
          
          <div className="width1">Library Funtion</div>
          <div className="width1">GD Library</div>
          <div className="width1">Session</div>
          <div className="width1">Cookies</div>
          <div className="width1">Email</div>
          
          </div>
          <div className="clearfix"></div>
          <div align="center">
         <Link to="/tutorial/codeigniter/68" className="btn btn-danger">Learn</Link>  </div>
          <div className="clearfix"></div>
          </div>
        </div>


        
         <div className="col-sm-4">
         <div align="center" className="div1">Codeigniter Example</div>
          <div className="box1">
          
          
           <div className="left">
          
          <div className="width1">Library Funtion</div>
          <div className="width1">GD Library</div>
          <div className="width1">Session</div>
          <div className="width1">Cookies</div>
          <div className="width1">Email</div>
             </div>
             <div className="clearfix"></div>
          <div align="center">
          <Link to="/example/codeigniter/66" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>

        </div>
        <div className="col-sm-4">
         <div align="center" className="div1">Codeigniter Interview</div>
          <div className="box1">
          
          
           <div className="left">
          
          <div className="width1">Codeigniter Feature</div>
          <div className="width1">Helper and Library</div>
          <div className="width1">Hooks in CodeIgniter</div>
          <div className="width1">Prevent SQL injection</div>
          <div className="width1">Databases supported</div>
             </div>
             <div className="clearfix"></div>
          <div align="center">
          <Link to="/interview/codeigniter" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>

        </div>

         <div className="col-sm-4">
         <div align="center" className="div1">Laravel Tutorial</div>
          <div className="box1">
          
          
           <div className="left">
          
          <div className="width1">Input/Output</div>
          <div className="width1">Insert Data</div>
          <div className="width1">Fetch Data</div>
          <div className="width1">Update/Delete</div>
          <div className="width1">Form&HTML</div>
             </div>
             <div className="clearfix"></div>
          <div align="center">
         <Link to="/tutorial/laravel/101" className="btn btn-danger">Learn</Link>  </div>
          <div className="clearfix"></div>
          </div>
          
        </div>

         <div className="col-sm-4">
         <div align="center" className="div1">Laravel Example</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">Insert Data by ORM</div>
          <div className="width1">Insert Data by Query</div>
          <div className="width1">Fetch Data</div>
          <div className="width1">Array</div>
          <div className="width1">OOPS</div>
            </div>
          <div className="clearfix"></div>
          <div align="center">
          <Link to="/example/laravel/68" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
          
        </div>
        <div className="col-sm-4">
         <div align="center" className="div1">Laravel Interview</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">PHP Artisan commands</div>
          <div className="width1">PSR-4 in Laravel</div>
          <div className="width1">Dependency Injection</div>
          <div className="width1">Laravel service container</div>
          <div className="width1">Default packages</div>
            </div>
          <div className="clearfix"></div>
          <div align="center">
         <Link to="/interview/laravel" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
          
        </div>
        
         <div className="col-sm-4">
         <div align="center" className="div1">API Tutorial</div>
          <div className="box1">
          
          
           <div className="left">
          
          <div className="width1">Input/Output</div>
          <div className="width1">Insert Data</div>
          <div className="width1">Fetch Data</div>
          <div className="width1">Update/Delete</div>
          <div className="width1">Form&HTML</div>
             </div>
             <div className="clearfix"></div>
          <div align="center">
         <Link to="/tutorial/api/127" className="btn btn-danger">Learn</Link>   </div>
          <div className="clearfix"></div>
          </div>
          
        </div>

         <div className="col-sm-4">
         <div align="center" className="div1">API Example</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">Insert Data by ORM</div>
          <div className="width1">Insert Data by Query</div>
          <div className="width1">Fetch Data</div>
          <div className="width1">Array</div>
          <div className="width1">OOPS</div>
            </div>
          <div className="clearfix"></div>
          <div align="center">
          <Link to="/example/api/68" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
          
        </div>
        <div className="col-sm-4">
         <div align="center" className="div1">API Interview</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">PHP Artisan commands</div>
          <div className="width1">PSR-4 in Laravel</div>
          <div className="width1">Dependency Injection</div>
          <div className="width1">Laravel service container</div>
          <div className="width1">Default packages</div>
            </div>
          <div className="clearfix"></div>
          <div align="center">
          <Link to="/interview/api" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
          
        </div>
        <div className="col-sm-4">
         <div align="center" className="div1">ReactJS Tutorial</div>
          <div className="box1">
          
          
           <div className="left">
          
          <div className="width1">Input/Output</div>
          <div className="width1">Insert Data</div>
          <div className="width1">Fetch Data</div>
          <div className="width1">Update/Delete</div>
          <div className="width1">Form&HTML</div>
             </div>
             <div className="clearfix"></div>
          <div align="center">
          <Link to="/tutorial/react/137" className="btn btn-danger">Learn</Link>
          </div>
          <div className="clearfix"></div>
          </div>
          
        </div>

         <div className="col-sm-4">
         <div align="center" className="div1">ReactJS Example</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">Insert Data by ORM</div>
          <div className="width1">Insert Data by Query</div>
          <div className="width1">Fetch Data</div>
          <div className="width1">Array</div>
          <div className="width1">OOPS</div>
            </div>
          <div className="clearfix"></div>
          <div align="center">
          <button type="button" className="btn btn-danger" 
          onClick="location.href='<?php echo base_url()?>user/example/api/68'">Learn</button>
          </div>
          <div className="clearfix"></div>
          </div>
          
        </div>
        <div className="col-sm-4">
         <div align="center" className="div1">ReactJS Interview</div>
          <div className="box1">
          
           <div className="left">
          <div className="width1">PHP Artisan commands</div>
          <div className="width1">PSR-4 in Laravel</div>
          <div className="width1">Dependency Injection</div>
          <div className="width1">Laravel service container</div>
          <div className="width1">Default packages</div>
            </div>
          <div className="clearfix"></div>
          <div align="center">
          <button type="button" className="btn btn-danger" 
          onClick="location.href='<?php echo base_url()?>user/interview/api/1'">Learn</button>
          </div>
          <div className="clearfix"></div>
          </div>
          
        </div>

    </div>
    
  </div>
  <div className="col-sm-2">
     <ins class="adsbygoogle"
      style={{ display: 'block' }}
     data-ad-client="ca-pub-0193275510308560"
     data-ad-slot="4784280750"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>

  </div>
</div>
</div></div>
    );
  }
}

export default MainPageContent;
