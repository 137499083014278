import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import { InterviewDetail } from './PublicFunctions';
import {Link} from "react-router-dom";
import Config from "./../../config";
import phplogo from "../../assets/images/phptutorial.png";
import javascriptlogo from "../../assets/images/javascripttutorial.png";
import jquerylogo from "../../assets/images/jquerytutorial.png";
import ajaxlogo from "../../assets/images/ajaxtutorial.png";
import codeigniterlogo from "../../assets/images/codeignitertutorial.png";
import laravellogo from "../../assets/images/laraveltutorial.png";
import apilogo from "../../assets/images/apitutorial.png";
import loadingImage from "../../assets/img/loading.gif";
//import reactjslogo from "../../assets/images/reactjstutorial.png";


class Interview extends React.Component {
    constructor() {
        super()
        this.state = {
            posts:[],
            submitted: false,
            isLoading : true
        }
    }

    componentDidMount() {
        const tutorialId = this.props.match.params.tutId;
        InterviewDetail(tutorialId).then(res => {
            this.setState({
                posts: res.data,
                isLoading: false
                
            })
        })
    }
  render() {
    const tutId = this.props.match.params.tutId;
    let tutlogo, tutname,count=1;
    const { name, email, mobile, submitted, error,isLoading,posts } = this.state;
    if (tutId=='php') {
      tutname="PHP";
    }
    else if (tutId=='javascript') {
      tutname="Javascript";
    }
    else if (tutId=='jquery') {
      tutname="jQuery";
    }
    else if (tutId=='ajax') {
      tutname="Ajax";
    }
    else if (tutId=='codeigniter') {
      tutname="Codeigniter";
    }
    else if (tutId=='laravel') {
      tutname="Laravel";
    }
    else if (tutId=='api') {
      tutname="API";
    }
    else if (tutId=='react') {
      tutname="React";
    }
    
    return (
      <div>
      {isLoading && (
              <div className="loading">
                <img src={loadingImage} alt={loadingImage} className="mx-auto d-block loading-image" ></img>
              </div>
            )}
        <Header />
        <main role="main">
        <div className="background">
        <div className="container">
        
          <div className="row">
          
  <div className="col-sm-10">
  <h1 className='heading1'>{tutname} Interview Questions and Answers</h1> 
  {posts.map((post, i) => {
            return (
            <div className="container" style={{paddingLeft:'0px',paddingRight:'0px'}} key={i}> 
            <div class="heading2">{post.topic_heading}</div>
            <div class="content">
            <pre>{post.topic_desc}</pre>
            </div>
            </div>            
            )
  })}
         
  </div>
<div className="col-sm-2">
 <div class="thumbnail">
     <div style={{fontSize:'18px',fontWeight:'bold',color:'#c32324'}}>Tutorial</div>
     <div>
      <a href="/tutorial/php/1" class="rightdiv1">PHP Tutorial</a>
      </div>
      <div>
      <a href="/tutorial/javascript/3" class="rightdiv1">Javascript Tutorial</a>
      </div>
      <div>
      <a href="/tutorial/jquery/64" class="rightdiv1">Jquery Tutorial</a>
      </div>
      <div>
       <a href="/tutorial/ajax/4"  class="rightdiv1">Ajax Tutorial</a>
       </div>
       <div>
       <a href="/tutorial/codeigniter/68" class="rightdiv1">Codeigniter Tutorial</a>
       </div>
        <div>
       <a href="/tutorial/laravel/101" class="rightdiv1">Laravel Tutorial</a>
       </div>
      <div>
       <a href="/tutorial/api/127" class="rightdiv1">API Tutorial</a>
       </div>
       <div>
       <a href="/tutorial/react/137" class="rightdiv1">React Tutorial</a>
       </div>
       <br/>
       <div style={{fontSize:'18px',fontWeight:'bold',color:'#c32324'}}>Example</div>
       <div>
      <a href="/example/php/1" class="rightdiv1">PHP Example</a>
      </div>
      <div>
      <a href="/example/javascript/23" class="rightdiv1">Javascript Example</a>
      </div>
      <div>
       <a href="/example/jquery/63" class="rightdiv1">Jquery Example</a>
       </div>
       <div>
       <a href="/example/ajax/33" class="rightdiv1">Ajax Example</a>
       </div>
       <div>
      <a href="/example/codeigniter/66" class="rightdiv1">Codeigniter Example</a>
      </div>
       <div>
      <a href="/example/laravel/68" class="rightdiv1">Laravel Example</a>
      </div>
      <div>
      <a href="/example/api/66" class="rightdiv1">API Example</a>
      </div>
      <div>
      <a href="/example/api/66" class="rightdiv1">React Example</a>
      </div>
      
      </div>
</div>

          </div>
          </div>
        
          </div>
        </main>         
        <Footer />
      </div>
    );
  }
}
export default Interview;
