import React, { Component } from "react";
import { Link,withRouter } from "react-router-dom";
import logo from "../../assets/img/logo-free.png";
import "../../assets/css/style2.css";
import "../../assets/css/custom.css";
import { isAuthenticated } from './../pages/UserFunctions'
class Header extends Component {
  logOut(e) {
    e.preventDefault()
    localStorage.removeItem('usertoken')
    this.props.history.push(`/`)
  }
  render() {
    const loginRegLink = (
        <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
                <Link to="/" className="nav-link dropdown-toggle menu">Home</Link>
            </li>
            <li className="nav-item dropdown">
                <Link to="/aboutus" className="nav-link dropdown-toggle menu">About Us</Link>
            </li>
            <li className="nav-item dropdown">
          <Link to="#" data-toggle="dropdown" className="nav-link dropdown-toggle menu">Tutorial <b className="caret"></b></Link>
          <ul className="dropdown-menu">
          <li><a href="/tutorial/php/1" className="linkstyle">PHP</a></li>
          <li><a href="/tutorial/javascript/3" className="linkstyle">Javascript</a></li>
          <li><a href="/tutorial/jquery/64" className="linkstyle">jQuery</a></li>
          <li><a href="/tutorial/ajax/4" className="linkstyle">Ajax</a></li>
          <li><a href="/tutorial/codeigniter/68" className="linkstyle">Codeigniter</a></li>
          <li><a href="/tutorial/laravel/101" className="linkstyle">Laravel</a></li>
          <li><a href="/tutorial/api/127" className="linkstyle">PHP API</a></li>
          <li className="marginzero"><a href="/tutorial/react/137" className="linkstyle">ReactJS</a></li>
         </ul>
        </li>
          <li className="nav-item dropdown">
          <Link to="#" data-toggle="dropdown" className="nav-link dropdown-toggle menu">Example<b className="caret"></b></Link>
          <ul className="dropdown-menu">
          <li><Link to="/example/php/1" className="linkstyle">PHP</Link></li>
          <li><Link to="/example/javascript/23" className="linkstyle">Javascript</Link></li>
          <li><Link to="/example/jquery/63" className="linkstyle">jQuery</Link></li>
          <li><Link to="/example/ajax/33" className="linkstyle">Ajax</Link></li>
          <li><Link to="/example/codeigniter/66" className="linkstyle">Codeigniter</Link></li>
          <li><Link to="/example/laravel/68" className="linkstyle">Laravel</Link></li>
          <li><Link to="/example/api/68" className="linkstyle">API</Link></li>
          <li className="marginzero"><Link to="/example/php/1" className="linkstyle">ReactJS</Link></li>
         </ul>
        </li>
          <li className="nav-item dropdown">
          <a to="#" data-toggle="dropdown" className="nav-link dropdown-toggle menu">Interview QA <b className="caret"></b></a>
          <ul className="dropdown-menu">
          <li><a href="/interview/php" className="linkstyle">PHP</a></li>
          <li><a href="/interview/javascript" className="linkstyle">Javascript</a></li>
          <li><a href="/interview/jquery" className="linkstyle">jQuery</a></li>
          <li><a href="/interview/ajax" className="linkstyle">Ajax</a></li>
          <li><a href="/interview/codeigniter" className="linkstyle">Codeigniter</a></li>
          <li><a href="/interview/laravel" className="linkstyle">Laravel</a></li>
          <li><a href="/interview/api" className="linkstyle">API</a></li>
          <li className="marginzero"><a href="/interview/react" className="linkstyle">ReactJS</a></li>
         </ul>
        </li>
            <li className="nav-item dropdown">
                <Link to="/contactus" className="nav-link dropdown-toggle menu">Contact Us</Link>
            </li>
       
            <li className="dropdown">
                <Link to="/postquestion" className="btn btn-success navbtn">Post Question</Link>
            </li>
        </ul>
    )

    const userLink = (
        <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
                <Link to="/" className="nav-link dropdown-toggle menu">Home</Link>
            </li>
             <li class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" class="linkstyle">Tutorial <b class="caret"></b></a>
          <ul class="dropdown-menu">
          <li><a href="<?php echo base_url()?>user/tutorial/php/1" class="linkstyle">PHP</a></li>
          <li><a href="<?php echo base_url()?>user/tutorial/javascript/3" class="linkstyle">Javascript</a></li>
          <li><a href="<?php echo base_url()?>user/tutorial/jquery/64" class="linkstyle">jQuery</a></li>
          <li><a href="<?php echo base_url()?>user/tutorial/ajax/4" class="linkstyle">Ajax</a></li>
          <li><a href="<?php echo base_url()?>user/tutorial/codeigniter/68" class="linkstyle">Codeigniter</a></li>
           <li><a href="<?php echo base_url()?>user/tutorial/laravel/101" class="linkstyle">Laravel</a></li>
         </ul>
        </li>
            <li className="nav-item dropdown">
                <Link to="/aboutus" className="nav-link dropdown-toggle menu">About Us</Link>
            </li>
            <li className="nav-item dropdown">
                <Link to="/contactus" className="nav-link dropdown-toggle menu">Contact Us</Link>
            </li>
            <li className="nav-item dropdown">
                <Link to="/profile" className="nav-link dropdown-toggle menu">Profile</Link>
            </li>
            <li className="nav-item dropdown">
                <a href="" onClick={this.logOut.bind(this)} className="nav-link dropdown-toggle menu">Logout</a>
            </li>
            <li className="dropdown">
                <Link to="/postfreeads" className="btn btn-success navbtn">Post Free Ads</Link>
            </li>
        </ul>
    )
    return (
      <header>
         <div class="top-menu">
            <div class="container">
               <div class="line">
                  <div className="topleft">info@ittrainingonline.co.in</div>
                  
                  <div className="topright">Call : 9911097721
                 
                  </div>
               </div>
            </div>
         </div>
           
         <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container">
            <Link to="/" className="nav-link dropdown-toggle menu"><img src={logo} alt="logo" class="img-fluid"/>
            </Link> 
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" id="nav-icon">
            <span class="navbar-toggler-icon"></span>
            <span></span>
            <span></span>
            <span></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              {isAuthenticated() ? userLink : loginRegLink}
            </div>
          </div>
         </nav>
        
        
      </header>
    );
  }
}

export default withRouter(Header);
