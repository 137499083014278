import React, {Component} from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Config from "./../../config";
import loadingImage from "../../assets/img/loading.gif";
class PostQuestion extends Component{
    constructor(props) {
      super(props);
      this.state = {
       
        ad_title : '',
        question : '',
        category : '',
        description : '',
        name : '',
        email : '',
        phone : '',
        submitted: false,
        isLoading : false
      };
    }
  
  componentDidMount() {        
        (window.adsbygoogle = window.adsbygoogle || []).push({});        
    }

    handleChange = e => {
      const { name, value } = e.target;
      this.setState({
        [name]: value
      });
    };
  
    
    postQs = () => {
          
        //event.preventDefault();
        this.setState({ submitted: true });
        const formData = new FormData();
        
        formData.append('question', this.state.question);
        formData.append('category', this.state.category);
        formData.append('description', this.state.description);
        formData.append('name', this.state.name);
        formData.append('email', this.state.email);
        formData.append('phone', this.state.phone);     
        
        if(this.state.question && this.state.description){
          this.setState({ isLoading: true });
            fetch(`${Config.apiurl}api/postquestion`, {
                method: 'post',
                body: formData
              })
              .then(response => response.json())
              .then(response => {
                if(response.Status===200){		
                  window.scrollTo(0, 0)
                  this.setState({ isLoading : false,message: <div class="alert alert-success" role="alert">Your Question is Successfully Send</div> });
                  this.setState({ question: " ", category:" ",description: "", name: "", 
                    email: "",phone:" ",submitted: false });
            }
                  
              })
              .catch(err => {
                this.setState({ isLoading: false });
                console.log(err);
              });
        }
      }

    render() {
      const { question, name, email,submitted, isLoading } = this.state;
        return (
         
          <div>
            {isLoading && (
              <div className="loading">
                <img src={loadingImage} alt={loadingImage} className="mx-auto d-block loading-image" ></img>
              </div>
            )}
            <Header />
            <main role="main">
            <div className="background">
            <div class="container topmargin">
              <div class="row">
              <div class="col-sm-3">
               <ins class="adsbygoogle"
      style={{ display: 'block' }}
     data-ad-client="ca-pub-0193275510308560"
     data-ad-slot="4784280750"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
              </div>
              <div class="col-sm-9">
              <div class="container rounded">
              <form >
              <div align="center"><h5>Post Question</h5></div>
              
              {this.state.message} 
              
              <div><h6>Fill Up Question Detail</h6></div>
              <div class="form-group row">
              <label for="staticEmail" className="col-sm-4 col-form-label">Question</label>
              <div className="col-sm-8">
              <input type="text" className="form-control" name="question" placeholder="Question"
              value={this.state.question} onChange={this.handleChange}/>
               {submitted && !question &&
                    <div className="help-block">Question is required</div>
                    }
              </div>
              </div>
              <div class="form-group row">
              <label for="staticEmail" className="col-sm-4 col-form-label">Category</label>
              <div className="col-sm-8">
              <select name="category" class="form-control" value={this.state.value} onChange={this.handleChange}>
              <option value="">Please Select Category</option>
              <option value="1">PHP</option>
            <option value="2">Javascript</option>
            <option value="3">jQuery</option>
            <option value="4">Ajax</option>
            <option value="5">Codeigniter</option>
            <option value="6">Laravel</option>
            <option value="7">API</option>
            <option value="8">ReactJs</option>
              </select>
              </div>
              </div>
              
            
              <div class="form-group row">
              <label for="staticEmail" className="col-sm-4 col-form-label">Description</label>
              <div className="col-sm-8">
              <textarea name="description" className="form-control" rows="3" value={this.state.ad_description} onChange={this.handleChange}></textarea>
              </div>
              </div>

              <div class="form-group row">
              <label for="staticEmail" className="col-sm-4 col-form-label">Name</label>
              <div className="col-sm-8">
              <input type="text" className="form-control" name="name" placeholder="Name"
              value={this.state.name} onChange={this.handleChange}/>
              {submitted && !name &&
                    <div className="help-block">Name is required</div>
                    }
              </div>
              </div>
              <div class="form-group row">
              <label for="staticEmail" className="col-sm-4 col-form-label">Email</label>
              <div className="col-sm-8">
              <input type="text" className="form-control" name="email" placeholder="Email"
              value={this.state.email} onChange={this.handleChange}/>
              {submitted && !email &&
                    <div className="help-block">Email is required</div>
                    }
              </div>
              
              </div>
              <div class="form-group row">
              <label for="staticEmail" className="col-sm-4 col-form-label">Phone No.</label>
              <div className="col-sm-8">
              <input type="text" className="form-control" name="phone" placeholder="Phone"
              value={this.state.phone} onChange={this.handleChange}/>
              </div>
              </div>
             
              
            
            
              <button type="button" className="btn btn-success"
            variant="contained"
            color="primary"
            onClick={this.postQs}>
          Submit</button>
              </form>
              </div>
              </div>
              </div>
              </div>
             
              <hr className="break margin-top-bottom-0" />
              </div>
            </main>         
            <Footer />
          </div>
        );
      }   
}

export default PostQuestion