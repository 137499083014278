import React, {Component} from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
//import banner1 from "../../assets/img/banner1.jpg";
//import banner2 from "../../assets/img/banner2.png";

class Contact extends Component{
    render() {
        return(
            <div>
            <Header />
            <main role="main">
            <div className="background">
            <div class="container">
              <div class="row">
              <div class="col-sm-9 aboutus">
              <h3>Contact us</h3>
<p><br/>
B-156/A5, New ashok nager, Delhi -96<br/><br/>
Mobile :- 9911-09-7721<br/><br/>
Email :- info@ittrainingonline.co.in, shishirphpdeveloper@gmail.com
</p>
              </div>
              <div class="col-sm-3">
              <form action="#">
              <div align="center"><h5>Enquire Now</h5></div>
              <div className="form-group">   
              <input type="text" className="form-control" id="name" placeholder="Name"/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" id="email" placeholder="Email"/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" id="email" placeholder="Mobile"/>
              </div>
              <div class="form-group">
              <textarea rows="2" cols="30" className="form-control" placeholder="Message"></textarea>
              </div>
              <button type="submit" className="btn btn-success">Submit</button>
              </form>
              </div>
              </div>
              </div>
            </div>
            </main>         
            <Footer />
          </div>
        );
    }
}

export default Contact;