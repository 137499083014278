import React, {Component} from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import banner1 from "../../assets/img/banner1.jpg";
import { login } from './UserFunctions'
class Login extends Component{

  constructor() {
    super()
    this.state = {
        email: '',
        password: '',
        errors: {},
        submitted: false,
        error : ''
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
}

onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
}
onSubmit(e) {
    e.preventDefault()
    this.setState({ submitted: true });
    const user = {
        email: this.state.email,
        password: this.state.password
    }

    const { email, password } = this.state;
    if(email && password){
        login(user).then(res => {
            if (res) {
                this.props.history.push(`/profile`)
            }else{
                this.setState({ error: true });
            }
        })
    }

}



    render() {
        const { email, password, submitted, error } = this.state;
        return (
            <div>

              <Header />
              <main role="main">
              <div className="container topmargin">
                <div className="row">
                <div className="col-sm-3">
                <img src={banner1} alt="banner1" className="img-fluid"/><br/><br/>
                </div>
                <div className="col-sm-6">
                <div className="container rounded">
                <form noValidate onSubmit={this.onSubmit}>
                <div align="center"><h5>User Login</h5></div>

                {this.state.message}
                <div><h6>Fill Detail</h6></div>
                    {error &&
                    <div className="help-block">Invalid Login Details</div>
                    }
                <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Email</label>
                <div className="col-sm-8">
                <input type="text" className="form-control" name="email" placeholder="Email"
                value={this.state.email} onChange={this.onChange}/>
                    {submitted && !email &&
                    <div className="help-block">Email is required</div>
                    }
                </div>
                </div>

                <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Password</label>
                <div className="col-sm-8">
                <input type="password" className="form-control" name="password" placeholder="Password"
                value={this.state.password} onChange={this.onChange}/>
                    {submitted && !password &&
                    <div className="help-block">Password is required</div>
                    }
                </div>
                </div>
                <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label"></label>
                <div className="col-sm-8">
                <button type="submit" className="btn btn-success" variant="contained" color="primary">
            Submit</button>
                </div>
                </div>

                </form>
                </div>
                </div>
                <div className="col-sm-3">
                <img src={banner1} alt="banner2" className="img-fluid"/><br/><br/>
                </div>
                </div>
                </div>

               
              </main>
              <Footer />
            </div>
          );

    }
}

export default Login;