import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import { LinkDetail } from './PublicFunctions';
import { TutorialDetail } from './PublicFunctions';
import {Link} from "react-router-dom";
import Config from "./../../config";
import phplogo from "../../assets/images/phptutorial.png";
import javascriptlogo from "../../assets/images/javascripttutorial.png";
import jquerylogo from "../../assets/images/jquerytutorial.png";
import ajaxlogo from "../../assets/images/ajaxtutorial.png";
import codeigniterlogo from "../../assets/images/codeignitertutorial.png";
import laravellogo from "../../assets/images/laraveltutorial.png";
import apilogo from "../../assets/images/apitutorial.png";
import loadingImage from "../../assets/img/loading.gif";
import reactjslogo from "../../assets/images/reactjs.png";


class Tutorial extends Component {
    constructor() {
        super()
        this.state = {
            postlink:[],
            posts:[],
            submitted: false,
            isLoading : true
        }
    }

    componentDidMount() {
        const tutorialId = this.props.match.params.tutId;
        const Id = this.props.match.params.Id;
        LinkDetail(tutorialId).then(res => {
            this.setState({
                postlink: res.data
                
            })
        })
        TutorialDetail(tutorialId,Id).then(res => {
            this.setState({
                posts: res.data,
                isLoading: false
                
            })
        })
    }
  render() {
    const tutId = this.props.match.params.tutId;
    let tutlogo, tutname,count=1;
    const { name, email, mobile, submitted, error,isLoading, posts,postlink } = this.state;
    if (tutId=='php') {
      tutlogo=phplogo;
      tutname="PHP Tutorial";
    }
    else if (tutId=='javascript') {
      tutlogo=javascriptlogo;
      tutname="Javascript Tutorial";
    }
    else if (tutId=='jquery') {
      tutlogo=jquerylogo;
      tutname="jQuery Tutorial";
    }
    else if (tutId=='ajax') {
      tutlogo=ajaxlogo;
      tutname="Ajax Tutorial";
    }
    else if (tutId=='codeigniter') {
      tutlogo=codeigniterlogo;
      tutname="Codeigniter Tutorial";
    }
    else if (tutId=='laravel') {
      tutlogo=laravellogo;
      tutname="Laravel Tutorial";
    }
    else if (tutId=='api') {
      tutlogo=apilogo;
      tutname="API Tutorial";
    }
    else if (tutId=='react') {
      tutlogo=reactjslogo;
      tutname="ReactJS Tutorial";
    }
    return (
      <div>
      {isLoading && (
              <div className="loading">
                <img src={loadingImage} alt={loadingImage} className="mx-auto d-block loading-image" ></img>
              </div>
            )}
        <Header />
        <main role="main">
        <div className="background">
        <div className="container">
        
          <div className="row">
          <div className="col-sm-3">
          <div className="leftblue">
      <div align="center" className="paddingleft">
<img src={tutlogo} width="240" height="180" alt="{tutname}"/>
      <div className="head4">{tutname}</div> 
      {postlink.map((post, i) => {
        return (
        <div key={i}> 
        <div class="typetext"><a href={post.id} class="tcolor">{post.cat_id} - {post.subcategory_name}</a>
        </div>
        </div>
        )
  })}  
      </div>
      </div>
</div>
  <div class="col-sm-7">
  {posts.map((post, i) => {
            return (
            <div className="container" key={i}> 
            <div class="heading2">{post.topic_heading}</div>
            <div class="content">
            <div dangerouslySetInnerHTML={{__html: post.topic_desc}}></div>
            </div>
            </div>
            
            )
  })}
         
          </div>
<div className="col-sm-2">
<div className="col-sm-2">
     <ins class="adsbygoogle"
      style={{ display: 'block' }}
     data-ad-client="ca-pub-0193275510308560"
     data-ad-slot="4784280750"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>

  </div>
  <div class="thumbnail">
     <div style={{fontSize:'18px',fontWeight:'bold',color:'#c32324'}}>Tutorial</div>
     <div>
      <a href="/tutorial/php/1" class="rightdiv1">PHP Tutorial</a>
      </div>
      <div>
      <a href="/tutorial/javascript/3" class="rightdiv1">Javascript Tutorial</a>
      </div>
      <div>
      <a href="/tutorial/jquery/64" class="rightdiv1">Jquery Tutorial</a>
      </div>
      <div>
       <a href="/tutorial/ajax/4"  class="rightdiv1">Ajax Tutorial</a>
       </div>
       <div>
       <a href="/tutorial/codeigniter/68" class="rightdiv1">Codeigniter Tutorial</a>
       </div>
        <div>
       <a href="/tutorial/laravel/101" class="rightdiv1">Laravel Tutorial</a>
       </div>
      <div>
       <a href="/tutorial/api/127" class="rightdiv1">API Tutorial</a>
       </div>
       <div>
       <a href="/tutorial/react/137" class="rightdiv1">React Tutorial</a>
       </div>
       <br/>
       <div style={{fontSize:'18px',fontWeight:'bold',color:'#c32324'}}>Example</div>
       <div>
      <a href="/example/php/1" class="rightdiv1">PHP Example</a>
      </div>
      <div>
      <a href="/example/javascript/23" class="rightdiv1">Javascript Example</a>
      </div>
      <div>
       <a href="/example/jquery/63" class="rightdiv1">Jquery Example</a>
       </div>
       <div>
       <a href="/example/ajax/33" class="rightdiv1">Ajax Example</a>
       </div>
       <div>
      <a href="/example/codeigniter/66" class="rightdiv1">Codeigniter Example</a>
      </div>
       <div>
      <a href="/example/laravel/68" class="rightdiv1">Laravel Example</a>
      </div>
      <div>
      <a href="/example/api/66" class="rightdiv1">API Example</a>
      </div>
      <div>
      <a href="/example/api/66" class="rightdiv1">React Example</a>
      </div>
      
      </div>
</div>

          </div>
          </div>
        
          </div>
        </main>         
        <Footer />
      </div>
    );
  }
}
export default Tutorial;
