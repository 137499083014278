import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from "./../pages/UserFunctions";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isAuthenticated() ?
                <Redirect to="/profile" />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;