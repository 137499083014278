import React, {Component} from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";

class About extends Component{
    render() {
        return (
          <div>
            <Header />
            <main role="main">
            <div className="background">
            <div class="container">
              <div class="row">
              <div class="col-sm-9 aboutus">
              <h3>About us</h3>
<div className="head6">WELCOME TO IT TRAINING ONLINE</div>

     IT training online is the best Online Tutorial for Freshers, which was established in the year 2010. It is offering online tutorials in a wide range of IT topics with Live practical example in very easy manner. Our site www.ittrainingonline.co.in provides online tutorials in six important topics- PHP, JavaScript, jQuery, Ajax, Codeigniter and Laravel. All beginners, BCA, MCA, Btech, students, can pursue these courses from our site to enhance their knowledge about these programming languages. We also have our physical existence in Janakpuri, Delhi region, where students are imparted with offline education. So, we provide you the opportunity to pursue education in both online and offline mode.
     
<div className="head5">About Author</div>     
     I'm Shishir Sharma. In my education I have done my BCA in 2003 and MCA in 2007
      from IGNOU. I have a 10 years’ experience worked as a PHP Developer.I have done work in Corephp, Codeigniter, Word press, Magento, Jquery, Ayax, Opencart, Vtiger, Suger CRM and Laravel. I'm working in the field of Website Development from last 10 years.
     <div>
     I am very creative in my work as I can combine both creativity and imagination at the same time so as to extract the 100% user-friendly environment and can work together with web applications and web solutions. I deliver end-to-end website design, development & digital marketing solutions that are focused on client goals and objectives. From strategy and design to internet marketing and mobile development, specialists deliver client success on every project assigned. I will help you exceed your expectations.
     
     
  
     
     <a href="http://shishirdeveloper.com/">More</a>
 <div className="head7">Mission</div> 
        Our mission is to deliver Best Online Free Tutorial for fresher, Those person who are not able to pay for training they can learn here Our Tutorial by example. My aim is to improve my tutorial more easy way, if you have any adivce then send me.
  

     </div>

              </div>
              <div class="col-sm-3 enquire">
              <form action="#">
              <div align="center"><h5>Enquire Now</h5></div>
              <div className="form-group">   
              <input type="text" className="form-control" id="name" placeholder="Name"/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" id="email" placeholder="Email"/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" id="email" placeholder="Mobile"/>
              </div>
              <div class="form-group">
              <textarea rows="2" cols="30" className="form-control" placeholder="Message"></textarea>
              </div>
              <button type="submit" className="btn btn-success">Submit</button>
              </form>
              </div>
              </div>
              </div>
            
             </div>
            </main>         
            <Footer />
          </div>
        );
      }   
}

export default About