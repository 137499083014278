import React, { Component } from "react";
import { Link } from "react-router-dom";
class Footer extends Component {
  render() {
    return (
      <footer> 
       <section class="padding background-dark">
        <div class="container">
          <div class="row">            
            <div class="col-sm-8 socialIcon">
                <a href="#" class="fa fa-facebook facebook"></a> 
                <a href="#" class="fa fa-youtube youtube"></a>
              <div class="clearfix"></div>
              <ul class="list-inline footerLinks">
                  <li class="list-inline-item">
                    <a href="aboutus">About us</a> |
                  </li>
                  <li class="list-inline-item">
                    <a href="contactus">Contact us</a> 
                  </li>
                
              </ul>              
            </div>
            <div class="col-sm-4">
                 <div class="boxHeight"></div>
                 <a href="" class="copyRight mt-5">All Rights Reserved ITTrainingonline</a>
            </div>
          </div>
        </div>
      </section>
    </footer>
    );
  }
}

export default Footer;
