import React, {Component} from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import RegisterContent from "../common/RegisterContent";


class Register extends Component{

    constructor(props) {
        super(props);
        this.initialState = {
          name: '',
          email: '',
          password: '',
          phone: '',
          address:'',
          location: '',
        }
        this.state = this.initialState;
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
       
    
        this.setState({
          [name]: value
        })
      }
    
      handleSubmit(event) {
          
        event.preventDefault();
        
        //const regUrl = ${config.apiUrl}+'/user-registration';
        fetch('/user-registration', {
            "method": "POST",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
            "body": JSON.stringify({
              name: this.state.name,
              email: this.state.email,
              password:'123123',
              phone: this.state.phone,
              address: this.state.address,
              location: this.state.location,
              

            })
          })
          .then(response => response.json())
          .then(response => {
            console.log(response)
          })
          .catch(err => {
            console.log(err);
          });
      }

    render() {
        return (
          <div>
            <Header />
            <main role="main">
            <div class="container">
              <div class="row">
              <div class="col-sm-3">
              <RegisterContent/>
              </div>
              <div class="col-sm-9">
              <form onSubmit={this.handleSubmit}>
              <div align="center"><h5>Register</h5></div>
              <div className="form-group">   
              <input type="text" className="form-control" name="name" placeholder="Name"
              value={this.state.name} onChange={this.handleChange}/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" name="email" placeholder="Email"
              value={this.state.email} onChange={this.handleChange}/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" name="phone" placeholder="Phone"
              value={this.state.phone} onChange={this.handleChange}/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" name="address" placeholder="Address"
              value={this.state.address} onChange={this.handleChange}/>
              </div>
              <div class="form-group">
              <input type="text" className="form-control" name="location" placeholder="Location"
              value={this.state.location} onChange={this.handleChange}/>
              </div>
             
              <button type="submit" className="btn btn-success">Submit</button>
              </form>
              </div>
              </div>
              </div>
             
              <hr className="break margin-top-bottom-0" />
            </main>         
            <Footer />
          </div>
        );
      }   
}

export default Register