import React, { Component } from 'react';
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/style2.css";
import { BrowserRouter, Switch,Route } from 'react-router-dom';
import Home from "./component/pages/Home";
import About from "./component/pages/About";
import Register from "./component/pages/Register";
import Login from "./component/pages/Login";
import ContactUs from "./component/pages/Contact";
import PostQuestion from "./component/pages/PostQuestion";
import Profile from "./component/pages/Profile";
import Tutorial from "./component/pages/Tutorial";
import Example from "./component/pages/Example";
import Interview from "./component/pages/Interview";
import PrivateRoute from "./component/auth/PrivateRoute";
import PublicRoute from "./component/auth/PublicRoute";


class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/aboutus" exact={true} component={About} />
            <Route path="/contactus" exact={true} component={ContactUs} />
            <PublicRoute path="/register" exact={true} component={Register} />
            <PublicRoute path="/login" exact={true} component={Login} />
            <PublicRoute path="/tutorial/:tutId/:Id" exact={true} component={Tutorial} />
            <PublicRoute path="/example/:tutId/:Id" exact={true} component={Example} />
            <PublicRoute path="/interview/:tutId" exact={true} component={Interview} />
            <Route path="/postquestion" exact={true} component={PostQuestion} />
            <PrivateRoute path="/profile" exact={true} component={Profile} />
          </Switch>
        </BrowserRouter>
    );
 }
}


export default App;